import Swiper from 'swiper/bundle';

document.addEventListener('turbo:load', () => {
  new Swiper('.customer-swiper', {
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
});
